// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-details-blog-details-js": () => import("./../../../src/Components/BlogDetails/BlogDetails.js" /* webpackChunkName: "component---src-components-blog-details-blog-details-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-contact-js": () => import("./../../../src/pages/Contact/Contact.js" /* webpackChunkName: "component---src-pages-contact-contact-js" */),
  "component---src-pages-for-candidates-job-search-index-js": () => import("./../../../src/pages/for-candidates/job-search/index.js" /* webpackChunkName: "component---src-pages-for-candidates-job-search-index-js" */),
  "component---src-pages-job-details-job-details-js": () => import("./../../../src/pages/JobDetails/JobDetails.js" /* webpackChunkName: "component---src-pages-job-details-job-details-js" */),
  "component---src-pages-job-listing-job-listing-js": () => import("./../../../src/pages/JobListing/JobListing.js" /* webpackChunkName: "component---src-pages-job-listing-job-listing-js" */),
  "component---src-pages-job-wishlist-js": () => import("./../../../src/pages/job-wishlist.js" /* webpackChunkName: "component---src-pages-job-wishlist-js" */),
  "component---src-pages-jobs-apply-model-js": () => import("./../../../src/pages/jobs/ApplyModel.js" /* webpackChunkName: "component---src-pages-jobs-apply-model-js" */),
  "component---src-pages-jobs-index-js": () => import("./../../../src/pages/jobs/index.js" /* webpackChunkName: "component---src-pages-jobs-index-js" */),
  "component---src-templates-default-template-js": () => import("./../../../src/templates/default-template.js" /* webpackChunkName: "component---src-templates-default-template-js" */),
  "component---src-templates-jobs-details-js": () => import("./../../../src/templates/jobs-details.js" /* webpackChunkName: "component---src-templates-jobs-details-js" */)
}

